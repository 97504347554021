import React from 'react';
import ReactDOM from 'react-dom/client';
import '@radix-ui/themes/styles.css';
import './theme-config.css'
import { Theme } from '@radix-ui/themes';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
<Theme appearance="dark" accentColor="pink">
    <App />
  </Theme>
  </React.StrictMode>
);
