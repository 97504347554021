import React from "react";
import { Box, Container, Heading } from "@radix-ui/themes";

export default function App() {
  const a = window.location.search;
  const isMobile = window.innerWidth < 768;
  const [b, setB] = React.useState("");
  React.useEffect(() => {
    if (a.includes("?")) {
      setB(decodeURI(atob(a.split("?")[1])));
    }
  }, [a]);

  return (
    <Container size="3">
      <img
        src="./IMG_8151.jpg"
        style={{
          width: "100%",
        }}
        alt="outside"
      />
      <Box style={{ display: "block", position: "relative" }}>
        <Heading
          size={isMobile ? "5" : "8"}
          color="amber"
          align="right"
          style={{
            position: "absolute",
            top: isMobile ? "-13%" : "-11%",
            right: "12%",
          }}
        >
          {b}
        </Heading>
        <img
          src="./IMG_8150.jpg"
          style={{
            width: "100%",
          }}
          alt="inside"
        />
      </Box>
    </Container>
  );
}
